import * as CommonClasses from "../../../cm2-commonclasses";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subscription, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Item, SenecaResponse, ItemTypes, Tag } from "../../../cm2-commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "../../shared/models/global-application-data.model";


@Injectable({
    providedIn: 'root'
})
export class WomenInsuranceNetworkService {
    applicationData: GlobalApplicationData;
    fromRecord: number;
    numRecords: number;
    result$: Subscription;
    currentPage: number;
    filterText: string;

    constructor(private store: Store<fromApp.AppState>,
        private http: HttpClient) {
        // Dallo Store applicativo, ricavo l'url per le chiamate rest e anche la pagina corrente in cui mi trovo nella visualizzazione dei template
        this.store.select(fromApp.getGlobalApplicationData).subscribe((result) => {
            this.applicationData = result;
        })

    }

    // Recupera il count degli item
    countContainerItems(searchedText?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-container-items', {
            params: httpParams
        });
    }

    // Recupera una lista di item
    searchContainerItems(searchedText: string, fromRecord: number, numRecords: number): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        let from = fromRecord;
        let to = numRecords;
        let searchdText = searchedText || '';
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('searchedText', searchdText);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/search-container-items', {
            params: httpParams
        });
    }

    // Restituisce la lista di tutti gli item con itemType container
    listContainerItemByAny(fromRecord?: number, numRecords?: number | string, referenceId?: string[], referenceApplicationName?: string[], referenceType?: string[]): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || '0');
        httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '25');
        httpParams = httpParams.append('sorting', 'T');
        if (referenceId && referenceId.length) {
            referenceId.forEach(referenceId => {
                httpParams = httpParams.append('referenceId', referenceId);
            });
        }
        if (referenceApplicationName && referenceApplicationName.length) {
            referenceApplicationName.forEach(referenceApplicationName => {
                httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
            });
        }
        if (referenceType && referenceType.length) {
            referenceType.forEach(referenceType => {
                httpParams = httpParams.append('referenceType', referenceType);
            });
        }
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-container-item-by-any', {
            params: httpParams
        });
    }

    // Recupera il count degli item
    countCoursesItems(searchedText?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-courses-items', {
            params: httpParams
        });
    }

    // Recupera una lista di item
    searchCoursesItems(searchedText: string, fromRecord: number, numRecords: number): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        let from = fromRecord;
        let to = numRecords;
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/search-courses-items', {
            params: httpParams
        });
    }

    getWomenInsuranceTagById(tagId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('tagId', tagId);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-women-insurance-tag-by-id', {
            params: httpParams
        });
    }

    updateWomenInsuranceTag(tagObj: Tag) {
        return this.http.post<CommonClasses.SenecaResponse<Tag>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-women-insurance-tag', {
            tag: tagObj
        });
    }
}

